import * as maplibre from 'maplibre-gl'

export const MAPTILER_TOKEN = process.env.GATSBY_MAPTILER_API_KEY

function extractFeature(property) {
  if (
    property.attributes.location.geo?.longitude &&
    property.attributes.location.geo?.latitude
  ) {
    return {
      // feature for Mapbox DC
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          property.attributes.location.geo.longitude,
          property.attributes.location.geo.latitude,
        ],
      },
      properties: property,
    }
  }
}

export { maplibre, extractFeature }
